import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CTA from "../../components/cta"
import ServiceAreas from "../../components/serviceAreas"
import SubServicesAlt from "../../components/subServicesAlt"
import Education from "../../components/education"
import HeroSectionAlt from "../../components/heroSectionAlt"
import Testimonials from "../../components/testimonials"

function Faucets() {
  const data = useStaticQuery(graphql`
    query faucetImages {
      faucet: file(relativePath: { eq: "faucet-repair-services.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Faucet Repair | Kitchen & Bathroom Faucets"
        description={`We offer thorough, fast, and affordable water line repair services. Our master plumber has over 30 years of experience in water line repair, so call now.`}
      />
      <HeroSectionAlt
        h1={`Faucet Repair & Installation`}
        h2={`Have a Master Plumber Handle Your Faucet Repair`}
        h3={`We have over three decades of experience fixing sink and bathroom faucets. No leaky or broken faucet is too much for us to handle - our master plumber will have it fixed right the first time.`}
        p1={`We've repaired countless faucets over 30 years of serving the North Houston area, which is why our service is fast, professional, and affordable.`}
        heroImg={data.faucet.childImageSharp.fluid}
        heroImgAltTag={`Faucet Repair`}
      />
      <Education
        mainTitle={`Signs You Might Need Faucet Repair`}
        titleOne={`Leaking Water`}
        descriptionOne={`If you see water leaking from the spout, faucet base, or under the sink, it can cause water damage, not to mention higher utility bills.`}
        titleTwo={`Low Water Pressure`}
        descriptionTwo={`Low faucet water pressure is annoying and can be caused by a few different things, primarily mineral deposits or old cartridges.`}
        titleThree={`Unusual Sounds`}
        descriptionThree={`Strange sounds coming from your faucet can be a signal that it needs a bit of maintenance to work properly again.`}
      />
      <SubServicesAlt
        mainTitle={`Our Faucet Services`}
        titleOne={`Leak Repair`}
        descriptionOne={`We can diagnose and fix your leak no matter where it's coming from so your faucet doesn't cause permanent damage and mold.`}
        titleTwo={`Water Pressure Correction`}
        descriptionTwo={`If your faucet is sputtering water or the pressure has decreased entirely, we can get it back to normal.`}
        titleThree={`Damaged Component Repair`}
        descriptionThree={`We can repair stuck handles, worn rubber washers, and more. If your faucet has general wear and tear, our repairs can help extend its life.`}
      />
      {/* <Testimonials mainTitle={`Our Customers`} /> */}
      <ServiceAreas
        description={`faucets`}
        subDescription={`faucet leaks and maintenance`}
      />
      <CTA
        title={`Get a Free Quote to Fix Your Faucet Now`}
        buttonTitle={`Talk With a Master Plumber`}
      />
    </Layout>
  )
}

export default Faucets
